import { FC, lazy, LazyExoticComponent } from 'react';

type AdaIconKeys = 'chat' | 'chatMobile';

export const AdaChatIcons: Record<
  AdaIconKeys,
  LazyExoticComponent<FC<{ leftIcon?: string; rightIcon?: string }>>
> = {
  chat: lazy(() => import('./chat-icon')),
  chatMobile: lazy(() => import('./chat-mobile-icon'))
};
