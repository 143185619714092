
import { ComponentCreator, createComponentCreator, styleInject } from 'packages/css-component-modules';

const _css = `.ChatContainer_ebf1bd372131ec48{cursor:pointer;position:fixed;bottom:40vh;right:-16px;transform:rotate(270deg);z-index:1}.ChatDesktopIcon_281c1f816559c60a{display:none}.ChatMobileIcon_37f20edb457908e1{display:block}@media (min-width:600px){.ChatContainer_ebf1bd372131ec48{transform:none;bottom:20px;right:30px}.ChatDesktopIcon_281c1f816559c60a{display:block}.ChatMobileIcon_37f20edb457908e1{display:none}}`;
styleInject(_css)

/** ChatContainer Props */
export type ChatContainerCCM = {
  /** ChatContainer Component Custom Properties */
  // No custom properties found

  /** ChatContainer Modifier Flags */
  // No modifiers classes found
};
/** Base ChatContainer component */
export const ChatContainer: ComponentCreator<ChatContainerCCM> = createComponentCreator({
  "name": "ChatContainer",
  "base": "ChatContainer_ebf1bd372131ec48",
  "prop": {},
  "mod": {}
});


/** ChatDesktopIcon Props */
export type ChatDesktopIconCCM = {
  /** ChatDesktopIcon Component Custom Properties */
  // No custom properties found

  /** ChatDesktopIcon Modifier Flags */
  // No modifiers classes found
};
/** Base ChatDesktopIcon component */
export const ChatDesktopIcon: ComponentCreator<ChatDesktopIconCCM> = createComponentCreator({
  "name": "ChatDesktopIcon",
  "base": "ChatDesktopIcon_281c1f816559c60a",
  "prop": {},
  "mod": {}
});


/** ChatMobileIcon Props */
export type ChatMobileIconCCM = {
  /** ChatMobileIcon Component Custom Properties */
  // No custom properties found

  /** ChatMobileIcon Modifier Flags */
  // No modifiers classes found
};
/** Base ChatMobileIcon component */
export const ChatMobileIcon: ComponentCreator<ChatMobileIconCCM> = createComponentCreator({
  "name": "ChatMobileIcon",
  "base": "ChatMobileIcon_37f20edb457908e1",
  "prop": {},
  "mod": {}
});

