import { FC, createElement } from 'react';
import { Anchor } from 'packages/react-nano-router';
import { AdaChatbotPlugin } from 'packages/ada-chatbot/plugin';
import {
  ChatContainer,
  ChatDesktopIcon,
  ChatMobileIcon
} from './ada-chatbot.ccm.css';
import { AdaChatIcons } from './icons';

export const useAdaChatbotAnchor = (callback?: () => void): Anchor => {
  return {
    href: 'javascript:void(0)',
    onClick: () => {
      callback && callback();
      AdaChatbotPlugin?.toggle();
    }
  };
};

export const AdaCustomChatButton: FC<{
  leftIconColor: string;
  rightIconColor: string;
}> = ({ leftIconColor, rightIconColor }) => {
  const DesktopIcon = AdaChatIcons.chat;
  const MobileIcon = AdaChatIcons.chatMobile;

  return (
    <ChatContainer.div onClick={() => window.adaEmbed.toggle()}>
      <ChatDesktopIcon.i>
        <DesktopIcon leftIcon={leftIconColor} rightIcon={rightIconColor} />
      </ChatDesktopIcon.i>
      <ChatMobileIcon.i>
        <MobileIcon leftIcon={leftIconColor} rightIcon={rightIconColor} />
      </ChatMobileIcon.i>
    </ChatContainer.div>
  );
};
